import React from 'react'
import SignUpForm from './SignUpForm/SIgnUpForm'

const SignUp = () => {
  
  return (
    <div style={{marginBottom: "30px", paddingTop: "1px"}}>
      <SignUpForm />
    </div>
  )
}

export default SignUp