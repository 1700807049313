import React from 'react'
import LogInForm from './LogInForm/LogInForm'

const LogIn = () => {
  return (
   <section style={{paddingTop: "40px", marginBottom: "30px"}}>
   <LogInForm />
   </section>
  )
}

export default LogIn